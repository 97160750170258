<template>
  <div v-if="!this.$route.meta.footerIsShow">
    <!-- 地图 -->
    <div class="map" v-if="$route.meta.showMap" data-aos="fade-up">
      <yandex-map
        :coords="$store.state.navShow == true ? map.coords : mapEn.coords"
        :option="$store.state.navShow == true ? map.option : mapEn.option"
        :zoom="$store.state.navShow == true ? map.zoom : mapEn.zoom"
        :controls="$store.state.navShow == true ? map.controls : mapEn.controls"
      >
        <ymap-marker
          v-for="(item, key) in $store.state.navShow == true ? map.markerList : mapEn.markerList"
          :key="key"
          :markerId="key"
          :coords="item.coords"
          :controls="item.controls"
          :icon="item.icon"
          :properties="item.properties"
        ></ymap-marker>
      </yandex-map>
      <!-- 下拉菜单 -->
      <div class="select" v-if="!$route.meta.hideSelect">
        <el-collapse
          v-model="mapSelectIndex"
          @change="mapSelectChange"
          accordion
        >
          <el-collapse-item
            :name="String(key)"
            v-for="(item, key) in $store.state.navShow == true ?  mapSelectList : mapSelectListEn"
            :key="key"
          >
            <!-- 标题 -->
            <template slot="title">
              <span class="title">{{ item.name }}</span>
            </template>
            <p class="site">
              <el-image :src="mapSelect.site"></el-image>
              <span>{{ item.address }}</span>
            </p>
            <p class="phone">
              <el-image :src="mapSelect.phone"></el-image>
              <span>{{ item.phone }}</span>
            </p>
            <p class="email">
              <el-image :src="mapSelect.email"></el-image>
              <span>{{ item.email }}</span>
            </p>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>
    <!-- 底部 -->
    <div class="project_footer">
      <div class="footer_main">
        <div class="project_footer_top">
          <!-- 联系我们 -->
          <div class="footer_contactUs">
            <h3 class="footer_title">{{$store.state.navShow == true ? '联系我们':'Contact Us'}}</h3>
            <div class="contactUs_info">
              <div class="contactUs_left">
                <div class="ewm">
                  <img :src="footerData.bottom_qr_code" alt="加载失败图片" />
                </div>
              </div>
              <div class="contactUs_right">
                <h5>{{$store.state.navShow == true ? '业务联系':'Tel'}}</h5>
                <h2>{{footerData.business_telephone }}</h2>
                <p>{{$store.state.navShow == true ? '电子邮箱：':'Email：'}}{{ footerData.business_mailbox }}</p>
                <p class="address">
                  <span>{{$store.state.navShow == true ? '公司地址：':'Address：'}}</span>
                  <span :style="$store.state.navShow == true ? '' : '    width: 200px;'">{{ $store.state.navShow == true ? footerData.company_address :' No. 399, Sports South Street, Shijiazhuang City, Hebei Province'}}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- 最新新闻 -->
          <div class="footer_newsXin">
            <h3 class="footer_title">{{$store.state.navShow == true ? '最新新闻':'Latest news'}}</h3>
            <div
              class="newsXin_info"
              v-for="(item, index) in $store.state.navShow == true ?  newsXinInfoArr.slice(0, 2) :  newsXinInfoArrEn.slice(0, 2)"
              :key="index"
              @click="newItemClick(item.id)"
            >
              <div class="img">
                <img :src="item.thumb" alt="" />
              </div>
              <div class="info_intro">
                <h5>{{ item.title }}</h5>
                <p>{{ item.content }}</p>
              </div>
            </div>
          </div>
          <!-- 行业资讯 -->
          <div class="footer_industryInfo">
            <h3 class="footer_title">{{$store.state.navShow == true ? '行业资讯':'Industry information'}}</h3>
            <div class="industryInfo_show">
              <div v-for="(item, index) in industryInfoShowArr" :key="index">
                <img :src="item.industryInfoIMg" alt="" />
              </div>

            </div>
          </div>
        </div>

        <div class="project_footer_bottom">
          <div class="bottom_l">
            <div class="jumpIcon" @click="vkClick">
              <img src="../assets/footer/shejiao01.png" alt="" />
              <img src="../assets/footer/shejiao01_active.png" alt="" />
            </div>
            <div class="jumpIcon" @click="facebookClick">
              <img src="../assets/footer/shejiao02.png" alt="" />
              <img src="../assets/footer/shejiao02_active.png" alt="" />
            </div>
            <div class="jumpIcon" @click="instagramClick">
              <img src="../assets/footer/shejiao03.png" alt="" />
              <img src="../assets/footer/shejiao03_active.png" alt="" />
            </div>
            <a class="jumpPages" @click="contactUsClick">{{$store.state.navShow == true ? '联系我们':'Contact Us'}}</a>
            <span style="margin: 0 10px">|</span>
            <a class="jumpPages" @click="newsCenterClick">{{$store.state.navShow == true ?'新闻中心':'Press Center'}}</a>
          </div>
          <div class="bottom_r">
            <span style="margin-right: 5px"
              >Copyright 2019-2020 © MG-GROUP
            </span>
            <time style="margin-top: -5px"
              >备案号：<span
                style="cursor: pointer; margin-left: -5px"
                @click="recordNo"
              >
                {{ footerData.web_site_icp }}</span
              ></time
            >
            <div class="badgeImg">
              <img src="../assets/footer/yingyezhizhao.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-backtop></el-backtop>
  </div>
</template>
<script>
export default {
  data () {
    return {
      footerData: '', // 底部除了新闻其余的数据
      footerDataEn: '', // 底部除了新闻其余的数据

      // 行业资讯展示的数组
      industryInfoShowArr: [
        {
          industryInfoIMg: require('../assets/footer/hangyezixun1.jpg') // 行业资讯展示的图标
        },
        {
          industryInfoIMg: require('../assets/footer/hangyezixun2.jpg')
        },
        {
          industryInfoIMg: require('../assets/footer/hangyezixun3.jpg')
        },
        {
          industryInfoIMg: require('../assets/footer/hangyezixun4.jpg')
        },
        {
          industryInfoIMg: require('../assets/footer/hangyezixun5.jpg')
        },
        {
          industryInfoIMg: require('../assets/footer/hangyezixun6.jpg')
        }
      ],
      // 最新新闻展示的数组
      newsXinInfoArr: [
        {
          newXinImg: require('../assets/footer/new1.jpg'),
          newXinTitle: 'MG打造新亮点|7月17日准时发行',
          newXinTitleintro: '中俄两国是山水相连的好邻居'
        },
        {
          newXinImg: require('../assets/footer/new2.jpg'),
          newXinTitle: 'MG打造新亮点|7月17日准时发行17日准时发行',
          newXinTitleintro: '中俄两国是山水相连的好邻居'
        },
        {
          newXinImg: require('../assets/footer/new2.jpg'),
          newXinTitle: 'MG打造新亮点|7月17日准时发行',
          newXinTitleintro: '中俄两国是山水相连的好邻居'
        }
      ],
      // 最新新闻展示的数组
      newsXinInfoArrEn: [
        {
          newXinImg: require('../assets/footer/new1.jpg'),
          newXinTitle: 'MG打造新亮点|7月17日准时发行',
          newXinTitleintro: '中俄两国是山水相连的好邻居'
        },
        {
          newXinImg: require('../assets/footer/new2.jpg'),
          newXinTitle: 'MG打造新亮点|7月17日准时发行17日准时发行',
          newXinTitleintro: '中俄两国是山水相连的好邻居'
        },
        {
          newXinImg: require('../assets/footer/new2.jpg'),
          newXinTitle: 'MG打造新亮点|7月17日准时发行',
          newXinTitleintro: '中俄两国是山水相连的好邻居'
        }
      ],
      // 地图浮层选择
      mapSelect: {
        site: require('../assets/home/site.png'), //  位置
        phone: require('../assets/home/phone.png'), //  手机号
        email: require('../assets/home/email.png') //  邮箱
      },
      //  地图
      map: {
        coords: [54.82896654088406, 39.831893822753904], //  中心位置
        zoom: 2, //  地图缩放
        controls: [
          'rulerControl', //  标尺
          'typeSelector', //  切换地图类型
          'zoomControl', //  缩放滑块
          'trafficControl', //  交通拥堵转状况
          'geolocationControl', //  确定用户位置
          'fullscreenControl' //  全屏控件
        ], //  地图控制器
        markerList: [] //  标记列表
      },
      //  地图 - 折叠面板相关信息
      mapSelectList: [
        {
          title: '河北',
          site: '河北省哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈市',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        },
        {
          title: '上海',
          site: '上海',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        },
        {
          title: '圣彼得堡',
          site: '圣彼得堡',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        },
        {
          title: '海参崴',
          site: '海参崴',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        }
      ],
      mapSelectIndex: '0', //  当前折叠打开的菜单位置

      //  地图
      mapEn: {
        coords: [54.82896654088406, 39.831893822753904], //  中心位置
        zoom: 2, //  地图缩放
        controls: [
          'rulerControl', //  标尺
          'typeSelector', //  切换地图类型
          'zoomControl', //  缩放滑块
          'trafficControl', //  交通拥堵转状况
          'geolocationControl', //  确定用户位置
          'fullscreenControl' //  全屏控件
        ], //  地图控制器
        markerList: [] //  标记列表
      },
      //  地图 - 折叠面板相关信息
      mapSelectListEn: [
        {
          title: '河北',
          site: '河北省哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈市',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        },
        {
          title: '上海',
          site: '上海',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        },
        {
          title: '圣彼得堡',
          site: '圣彼得堡',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        },
        {
          title: '海参崴',
          site: '海参崴',
          phone: '+86 13333333333',
          email: '13333333333@163.com'
        }
      ],
      mapSelectIndexEn: '0' //  当前折叠打开的菜单位置
    }
  },

  mounted () {
    // this.initMarker()

    /**
     * 底部数据 接口
     * 肖明霞
     * 2021-11-22
     */
    this.$axios.get('/api/Index/footer').then((res) => {
      // console.log('底部数据=>',res);
      if (res.data.code === 1) {
        // 除了新闻 其余的数据
        this.footerData = res.data.data.footerConfig
        // console.log('底部数据除了新闻=>',this.footerData)
        // 最新新闻
        this.newsXinInfoArr = res.data.data.news.map((item) => {
          // console.log('item=>',item)
          return item
        })
        console.log('底部最新新闻=>', this.newsXinInfoArr)
      } else {
        this.$message({
          showClose: true,
          message: '底部数据：' + res.data.msg,
          type: 'error'
        })
      }
    })
    this.$axios.get('/apien/Index/footer').then((res) => {
      // console.log('底部数据=>',res);
      if (res.data.code === 1) {
        // 除了新闻 其余的数据
        this.footerDataEn = res.data.data.footerConfig
        // console.log('底部数据除了新闻=>',this.footerData)
        // 最新新闻
        this.newsXinInfoArrEn = res.data.data.news.map((item) => {
          // console.log('item=>',item)
          return item
        })
        console.log('底部最新新闻=>', this.newsXinInfoArr)
      } else {
        this.$message({
          showClose: true,
          message: '底部数据：' + res.data.msg,
          type: 'error'
        })
      }
    })

    /**
     * 友情链接与合作公司地址 接口
     * 肖明霞
     * 2021-11-23
     */
    if (!this.$route.meta.hideSelect) {
      this.$axios.get('/api/Index/linksAndAddress').then((res) => {
        console.log('友情链接与合作公司地址', res.data)
        if (res.data.code === 1) {
          // 地图上显示的卡片
          this.mapSelectList = res.data.data.addressCard
          // console.log('this.mapSelectList', this.mapSelectList)

          // 地图标记
          this.map.markerList = res.data.data.address.map((item) => {
            return {
              coords: [Number(item.latitude), Number(item.longitude)],
              icon: {
                color: 'blue', //  图标颜色
                glyph: 'HomeCircle' //  中心图标类型
              },
              properties: {
                iconCaption: item.name,
                balloonContent: this.initInfoContent({
                  img: 'http://www.margingroup.cn' + item.thumb,
                  site: item.address,
                  phone: item.phone,
                  email: item.email
                })
              }
            }
          })
          // console.log('this.map.markerList =》 ', this.map.markerList)

          // 中心位置也跟着中心变化
          this.map.coords = this.map.markerList[0].coords
        } else {
          this.$message({
            showClose: true,
            message: '地图，' + res.data.msg,
            type: 'error'
          })
        }
      })
    }

    if (!this.$route.meta.hideSelect) {
      this.$axios.get('/apien/Index/linksAndAddress').then((res) => {
        console.log('友情链接与合作公司地址', res.data)
        if (res.data.code === 1) {
          // 地图上显示的卡片
          this.mapSelectListEn = res.data.data.addressCard
          // console.log('this.mapSelectList', this.mapSelectList)

          // 地图标记
          this.mapEn.markerList = res.data.data.address.map((item) => {
            return {
              coords: [Number(item.latitude), Number(item.longitude)],
              icon: {
                color: 'blue', //  图标颜色
                glyph: 'HomeCircle' //  中心图标类型
              },
              properties: {
                iconCaption: item.name,
                balloonContent: this.initInfoContent({
                  img: 'http://www.margingroup.cn' + item.thumb,
                  site: item.address,
                  phone: item.phone,
                  email: item.email
                })
              }
            }
          })
          // console.log('this.map.markerList =》 ', this.map.markerList)

          // 中心位置也跟着中心变化
          this.mapEn.coords = this.mapEn.markerList[0].coords
        } else {
          this.$message({
            showClose: true,
            message: '地图，' + res.data.msg,
            type: 'error'
          })
        }
      })
    }

    /**
     * 联系我们页面seo与公司地标  地图接口
     * 肖明霞
     * 2021-11-22
     */
    // console.log('this',this.$route.meta.hideSelect)
    if (this.$route.meta.hideSelect === true) {
      this.$axios.get('/api/Contactus/index').then((result) => {
        console.log('联系我们', result.data)
        if (result.data.code === 1) {
          this.map.markerList = result.data.data.address.map((item) => {
            return {
              coords: [Number(item.latitude), Number(item.longitude)],
              icon: {
                color: 'blue', //  图标颜色
                glyph: 'HomeCircle' //  中心图标类型
              },
              properties: {
                iconCaption: item.name,
                balloonContent: this.initInfoContent({
                  img: item.thumb,
                  site: item.address,
                  phone: item.phone,
                  email: item.email
                })
              }
            }
          })
          // 中心位置也跟着中心变化
          this.map.coords = this.map.markerList[0].coords
        }
      })
    }
  },
  methods: {
    /**
     * 联系我们 点击事件
     * 肖明霞
     * 2021-11-26
     */
    contactUsClick () {
      this.$router.push({
        path: '/ownJumpPage',
        query: {
          pathUrl: '/contactUs_index' // 跳转到联系我们
        }
      })
    },
    /**
     * 新闻中心 点击事件
     * 肖明霞
     * 2021-11-26
     */
    newsCenterClick () {
      this.$router.push({
        path: '/ownJumpPage',
        query: {
          pathUrl: '/news' // 跳转到联系我们
        }
      })
    },
    /**
     * 最新新闻每一项 点击事件
     * 肖明霞
     * 2021-11-22
     */
    newItemClick (id) {
      this.$router.push({
        path: '/ownJumpPage',
        query: {
          pathUrl: '/new_details', // 跳转到详情
          newId: id
        }
      })
    },

    /**
     * vk 点击事件
     * 肖明霞
     * 2021-11-22
     */
    vkClick () {
      window.open(this.footerData.vk_link, '_blank')
    },
    /**
     * facebook 点击事件
     * 肖明霞
     * 2021-11-22
     */
    facebookClick () {
      window.open(this.footerData.facebook_link, '_blank')
    },
    /**
     * instagram 点击事件
     * 肖明霞
     * 2021-11-22
     */
    instagramClick () {
      window.open(this.footerData.instagram_link, '_blank')
    },
    /**
     * 备案号 点击事件
     * 肖明霞
     * 2021-11-30
     */
    recordNo () {
      window.open('https://beian.miit.gov.cn', '_blank')
    },

    /**
     * 地图 - 折叠面板更新事件
     */
    mapSelectChange (event) {
      if (event !== '') {
        this.mapSelectIndex = event
      }
    },

    /**
     * 初始化地图标记
     */
    initMarker () {
      this.map.markerList = [
        {
          coords: [54.82896654088406, 39.831893822753904],
          icon: {
            color: 'blue', //  图标颜色
            glyph: 'HomeCircle' //  中心图标类型
          },
          properties: {
            iconCaption: '这里是标记文字',
            balloonContent: this.initInfoContent({
              img: this.industryInfoShowArr[0].industryInfoIMg,
              site: '北京市昌平区',
              phone: '+86 15033333333',
              email: '10000000@163.com'
            })
          }
        },
        {
          coords: [54.82896654088406, 40],
          icon: {
            color: 'blue', //  图标颜色
            glyph: 'HomeCircle' //  中心图标类型
          },
          properties: {
            iconCaption: '这里是标记文字',
            balloonContent: this.initInfoContent({
              img: this.industryInfoShowArr[0].industryInfoIMg,
              site: '北京市昌平区',
              phone: '+86 15033333333',
              email: '10000000@163.com'
            })
          }
        }
      ]
    },
    /**
     * 创建地图信息模板
     */
    initInfoContent (option) {
      const { img, site, phone, email } = option
      return `
            <div class="TemplateTip">
                <div class="image">
                    <img src="${img}">
                </div>
                <div class="content">
                    <p class="city">${site}</p>
                    <p>
                        <span>Phone：</span>
                        <span>${phone}</span>
                    </p>
                    <p>
                        <span>E-mail：</span>
                        <span>${email}</span>
                    </p>
                </div>
            </div>
        `
    }
  }
}
</script>
<style lang="less" scoped>
// 地图
.map {
  width: 100%;
  height: 600px;
  position: relative;
  .ymap-container {
    width: 100%;
    height: 100%;
  }
  .select {
    width: 370px;
    height: 382px;
    position: absolute;
    top: calc(calc(100% - 382px) / 2);
    left: 360px;
    display: flex;
    align-items: center;
    & .title {
      display: flex;
      align-items: center;
      &::before {
        content: "";
        display: inline-block;
        width: 12px;
        height: 3px;
        margin-right: 15px;
        background-color: #2d6aab;
      }
    }
    & p {
      display: flex;
      align-items: center;
      line-height: 28px;
      color: #2d6aab;
      width: 100%;
      & > .el-image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        min-width: 15px;
      }
      &.site {
        height: 48px;
        color: #666666;
        margin-bottom: 6px;
        & > .el-image {
          height: 18px;
        }
      }
      &.phone {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -2px;
        & > .el-image {
          height: 16px;
        }
      }
      &.email {
        & > .el-image {
          height: 13px;
        }
      }
      & > span {
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}
</style>
<style lang="less">
.project_footer {
  width: 100%;
  height: 400px;
  background: #2d6aab;
  .footer_main {
    width: 1200px;
    margin: 0 auto;
    // background: purple;
    overflow: hidden;
    .project_footer_top {
      // background: orange;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #6faae4;
      margin-top: 20px;
      padding-bottom: 50px;
      div {
        color: #fff;
      }
      // 底部标题
      .footer_title {
        height: 70px;
        // background: red;
        color: #fff;
        text-align: left;
        display: flex;
        align-items: center;
      }
      // 联系我们
      .footer_contactUs {
        // background: pink;
        .contactUs_info {
          display: flex;
          // background: greenyellow;
          .contactUs_left {
            // background: red;
            .ewm {
              width: 150px;
              height: 150px;
              // background: grey;
            }
          }
          .contactUs_right {
            flex: 0.9;
            padding-left: 20px;
            box-sizing: border-box;
            // background: green;
            text-align: left;
            h2 {
              margin: 10px 0 20px 0;
              // letter-spacing: 2px;
            }
            p {
              font-size: 14px;
              line-height: 25px;
              font-size: 12px;
            }
            .address {
              display: flex;
              // justify-content: space-between;

              & > span:first-child {
                width: 5.6em;
                // background: orange;
              }
              span:last-child {
                // flex: 2.1;
              }
            }
          }
        }
      }
      //最新新闻
      .footer_newsXin {
        flex: 0.5708;
        // background: yellow;
        // margin:0 75px;
        margin-left: 38px;
        margin-right: 75px;

        .newsXin_info {
          display: flex;
          height: 70px;
          margin-bottom: 10px;
          // background: red;
          cursor: pointer;
          transition: all 0.2s;
          .img {
            width: 120px;
            height: 70px;
            // background: orange;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .info_intro {
            width: calc(100% - 120px);
            height: 70px;
            background: #235f9d;
            padding: 15px;
            box-sizing: border-box;
            text-align: left;
            & > p {
              // background: orange;
              width: 200px;
              font-size: 12px;
              line-height: 30px;
              color: #6faae4;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; // 超出1行 出现...
              -webkit-box-orient: vertical;
            }
            & > h5 {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1; // 超出1行 出现...
              -webkit-box-orient: vertical;
            }
          }

          &:hover {
            transform: scale(1.03);
            // &:hover h5{

            //     color: #2E67B1;
            // }
          }
        }
      }
      // 行业资讯
      .footer_industryInfo {
        flex: 0.361;
        text-align: left;
        // background: red;
        .industryInfo_show {
          display: flex;
          flex-wrap: wrap;
          & > div {
            min-width: 70px;
            max-width: 70px;
            height: 70px;
            // background: gray;
            margin-left: 10px;
            margin-bottom: 10px;
            &:nth-child(1) {
              margin-left: 0px;
            }
            &:nth-child(4) {
              margin-left: 0px;
            }
          }

          // background: green;
          // margin-bottom: 6px;
          // span{
          //     display: inline-block;
          //     width: 90px;
          //     height: 90px;
          //     background: gray;
          //     margin-left: 10px;
          //     img{
          //         width: 100%;
          //         height: 100%;
          //     }
          // }
        }
      }
    }
    .project_footer_bottom {
      height: 100px;
      // background: green;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // & > div{
      //     flex: 1;
      // }
      .bottom_l {
        text-align: left;
        //    background: orange;
        height: 35px;
        display: flex;
        align-items: center;
        color: #6faae4;
        .jumpIcon {
          display: inline-block;
          width: 40px;
          height: 40px;
          border-radius: 50px;
          background: #235f9d;
          margin-right: 30px;
          cursor: pointer;
          & > img:nth-child(2) {
            display: none;
          }
          &:hover img:nth-child(1) {
            display: none;
          }
          &:hover img:nth-child(2) {
            display: block;
          }
        }
        .jumpPages {
          // display: inline-block;
          // width: 85px;
            //  background: pink;
          // text-align: center;
          cursor: pointer;
          &:hover {
            color: #fff;
            font-weight: 900;
          }
        }
      }
      .bottom_r {
        //  background: red;

        display: flex;
        align-items: center;
        //    justify-content: space-between;
        color: #6faae4;

        .badgeImg {
          display: inline-block;
          //  width: 35px;
          //  height: 35px;
          //    background-color: grey;
          margin-left: 20px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
// 地图
.map {
  .el-collapse {
    width: 100%;
    .el-collapse-item__header {
      height: 60px;
      box-sizing: border-box;
      padding-left: 30px;
      padding-right: 17px;
      background-color: #f5fafd;
      font-size: 18px;
      font-weight: bold;
      color: #333;
      &.is-active {
        background-color: #2d6aab !important;
        color: #fff;
        & .title::before {
          background-color: #fff;
        }
      }
    }
    .el-collapse-item__content {
      width: 100%;
      height: 140px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      padding: 15px 30px;
      box-sizing: border-box;
    }
  }
}
// 模板消息
.TemplateTip {
  display: flex;
  width: 400px;
  height: 100px;
  & > div {
    flex: 1;
    height: 100px;
    &.image {
      max-width: 100px;
      min-width: 100px;
      height: 100px;
      & > img {
        width: 100%;
        height: 100%;
        // object-fit: none;
      }
    }
    &.content {
      flex: 1;
      padding-left: 20px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      & > p {
        font-size: 12px;
        line-height: 24px;
        color: #2d6aab;
        text-align: left;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }
      & > p.city {
        color: #666666;
        height: 48px;
        -webkit-line-clamp: 2;
      }
    }
  }
}
</style>
