<template>
    <div class="widthPx">
        <router-view></router-view>
        <x-footer></x-footer>
    </div>

</template>
<script>
import xFooter from '../components/footer.vue'
export default {
  components: {
    // 使用/注册引入的组件 components
    xFooter
  }
}
</script>
<style>
    *{
        margin: 0;
        padding: 0;
        list-style: none;
        text-decoration: none;
    }

    html,body{
        scroll-behavior:smooth;
    }

    .widthPx{
        --width1200px :1200px;
        overflow: hidden;
    }
</style>
<style>
     table{
        width: 100%;
    }
    .el-table thead{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        /* background: orange; */
    }
    .el-table tr{
        background: #f8f8f8!important;
    }
    .el-table__header{
        width: 100%!important;
        /* background: orange; */
    }
    .el-table--border, .el-table--group {
        border: none;
    }
    .el-table--border::after, .el-table--group::after, .el-table::before {
        background: transparent;
    }
    .el-table th.el-table__cell {
        background: #f8f8f8!important;
    }
    .el-table__body{
        font-size: 16px!important;
        font-family: Microsoft YaHei!important;
        font-weight: 400!important;
        color: #666666!important;
        width: 100%!important;
    }
    .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf{
        border-bottom: 3px solid #fff;
    }
    .el-table--border .el-table__cell, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed{
         border-right: 3px solid #fff;
    }
</style>
